<template>
  <div class="registration wrapper auth-layout">
    <div class="registration-form" v-if="isEmailSent">
      <div class="form-wrapper">
        <b-card-body>
          <div class="text-center mt-4 mb-4">
            <b-icon icon="check-circle-fill" variant="success" scale="6" />
          </div>
          <h3 class="pt-4 mb-2 text-center">
            Виглядає добре!
          </h3>
          <p class="mb-5 text-center">
            Ми надіслали електронною поштою посилання для скидання паролю! Також
            перевірте папку зі спамом.
          </p>
        </b-card-body>
      </div>
    </div>
    <div class="registration-form" v-else>
      <div class="aml-logo">
        <img src="/images/aml-logo.svg" alt="aml" />
      </div>
      <div class="form-wrapper">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <h1 class="form-title">Відновити пароль</h1>
          <form action="" @submit.prevent="handleSubmit(onSubmit)">
            <p class="form-text">
              Введіть E-mail, який ви вказували під час реєстрації, і ми
              надішлемо вам інструкції для відновлення пароля
            </p>

            <div class="form-input-container">
              <ValidationProvider
                vid="email"
                name="адреса електронної пошти"
                rules="required|email"
                v-slot="{ errors, dirty }"
              >
                <input
                  placeholder="E-mail"
                  type="email"
                  name="email"
                  class="form__input form__input_primary"
                  v-model="email"
                  :disabled="submitting"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <button
              type="submit"
              class="form__button"
              :disabled="submitting"
              :class="{
                'btn-light': submitting,
                'btn-success': !submitting
              }"
            >
              <span v-if="!submitting">
                Відправити інструкції
              </span>
              <span v-else>
                Відправлення...
              </span>
            </button>
            <div class="form__link">
              Згадали пароль?
              <b-link :to="{ name: 'Login' }">
                Увійти
              </b-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";

export default {
  name: "ForgotPassword",
  data: () => ({
    email: "",
    isEmailSent: false,
    submitting: false
  }),
  methods: {
    onSubmit() {
      this.submitting = true;
      api
        .forgotPassword({ email: this.email })
        .then(() => {
          this.submitting = false;
          this.isEmailSent = true;
        })
        .catch(({ response }) => {
          if (response.status !== 406) {
            this.submitting = false;
            return;
          }

          if (!response.data.description || !response.data.description.email) {
            this.submitting = false;
            return;
          }

          this.$refs.form.setErrors({
            email: "Ми не змогли знайти користувача з цією електронною адресою."
          });
          this.submitting = false;
        });
    }
  }
};
</script>
<style>
body,
p {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova";
}
* {
  transition: all 0.2s ease;
  box-sizing: border-box;
  color: inherit;
  text-decoration: none;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Light.ttf);
  font-weight: 300;
}
</style>

<style scoped>
a > img {
  width: 100px;
}
.aml-logo {
  margin-top: -16px;
}
.form-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475066;
  margin-top: 24px;
}
.form__input {
  margin-top: 26px;
}
</style>
